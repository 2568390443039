import { Trans, t } from "@lingui/macro"
import { array } from "fp-ts"
import Link from "next/link"
import ArrowRightIcon from "remixicon-react/ArrowRightLineIcon"

import { imageAltOf, previewImageOf } from "@/domains/artworks/model"
import { ArtworkInlineDetails } from "@/domains/artworks/view/inline-details"
import { AuctionPreview, View, hrefOf } from "@/domains/auctions/model"
import { DimensionsView } from "@/domains/dimensions/view"
import { CurrencyProvider, MoneyView } from "@/domains/money"
import { TechniquesView } from "@/domains/techniques/view"
import { useCountdown } from "@/lib/countdown"
import { useI18n } from "@/lib/i18n"
import { hiContrastDarkTheme, styled } from "@/stitches"
import { AspectRatio } from "@/ui/aspect-ratio"
import {
  AspectRatioImage,
  ColorLayer,
  IconLayer,
} from "@/ui/aspect-ratio-image"
import { Box } from "@/ui/box"
import { Skeleton } from "@/ui/skeleton"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

function CountdownNumber({ number, label }: { number: number; label: string }) {
  return (
    <Stack direction="column" css={{ textAlign: "center" }}>
      <Text size="xl" weight="bold">
        {number}
      </Text>
      <Text variant="uppercase" size="xs" weight="medium" color="muted">
        {label}
      </Text>
    </Stack>
  )
}

function Countdown({
  endsAt,
  figuresCount,
}: {
  endsAt: Date
  figuresCount: number
}) {
  const countdown = useCountdown({ endsAt, significantParts: figuresCount })
  const { i18n } = useI18n()

  return (
    <Stack align="center" justify="center" gap={4} css={{ my: "$3" }}>
      {countdown.overdue ? (
        <Box textAlign="center">
          <Text as="p" weight="bold" nowrap>
            <Trans id="auctionCard.auctionEnded">Aukce skončila</Trans>
          </Text>
          <Text as="p" variant="label" nowrap>
            {i18n.date(endsAt, { dateStyle: "medium", timeStyle: "short" })}
          </Text>
        </Box>
      ) : (
        countdown.parts.map(({ key, value, label }) => (
          <CountdownNumber
            key={key}
            number={value}
            label={label.substring(0, 3)}
          />
        ))
      )}
    </Stack>
  )
}

const ImageWrapper = styled(Box, {})

const Name = styled(Box, {
  zIndex: 10,
})

const Technique = styled("div", {})

const Dimensions = styled("div", {})

const Status = styled(Box, {
  display: "flex",
  minHeight: "5rem",
  overflow: "hidden",
})

const ContainerGrid = styled("a", {
  display: "grid",
  overflow: "hidden",
  variants: {
    view: {
      list: {
        alignItems: "center",
        gap: "$6",
        gridTemplateColumns: "repeat(2, 1fr)",
        pb: "$4",

        [`${Status}`]: {
          gridColumn: "span 2",
        },

        ".inline-artwork-details": {
          display: "none",
        },

        "@md": {
          gridTemplateColumns: "1fr 1.5fr repeat(3, 1fr) 0.5fr",

          [`${Status}`]: {
            flexDirection: "column",
          },
        },

        "@lg": {
          gridTemplateColumns: "1fr 1.5fr repeat(4, 1fr)",

          [`${Status}`]: {
            flexDirection: "row",
          },
        },
      },
      grid: {
        [`${ImageWrapper}`]: {
          gridRow: "1 / 2",
          gridColumn: "1 / 2",
        },

        [`${Name}`]: {
          gridRow: "1 / 2",
          placeSelf: "end start",
          padding: "$6",
          "@animation": {
            transition: "opacity 120ms, transform 120ms",
          },
        },

        [`${Status}`]: {
          gridRow: "2 / 3",
        },

        [`&:hover ${Name}`]: {
          opacity: 0,
          transform: "translateX(-5%)",
        },
      },
      gridCompact: {
        gridTemplateRows: "auto auto 1fr",

        [`${Name}`]: {
          mt: "$4",
        },

        ".technique, .dimensions": {
          display: "none",
        },

        [`${Status}`]: {
          gridRow: "2 / 3",
        },
      },
    },
  },
})

export type AuctionCardProps = {
  auction: AuctionPreview
  figuresCount?: number
  hideAuthor?: boolean
  view?: View
}

export function AuctionCard({
  auction,
  view = "grid",
  figuresCount = 3,
  hideAuthor = false,
}: AuctionCardProps) {
  const { localize } = useI18n()
  const { artwork } = auction
  const artworkName = localize(artwork.name, { fallback: artwork.originalName })

  return (
    <CurrencyProvider rates={auction.finishedWithCurrencyRates}>
      <Link href={hrefOf(auction)} passHref>
        <ContainerGrid className="group" view={view}>
          <ImageWrapper>
            <AspectRatioImage
              image={previewImageOf(artwork)}
              alt={imageAltOf(artwork)}
            >
              <ColorLayer variant={view === "grid" ? "dimmed" : "default"} />
              <IconLayer>
                <ArrowRightIcon direction="right" size="4em" />
              </IconLayer>
            </AspectRatioImage>
          </ImageWrapper>
          <Name className={view === "grid" ? hiContrastDarkTheme : undefined}>
            {!hideAuthor && (
              <Text as="p" size="xl" weight="black">
                {artwork.author?.name ?? t`author.unknown`}
              </Text>
            )}
            <Text as="p" size="xl" weight={hideAuthor ? "bold" : "normal"}>
              {artworkName}
            </Text>
            <ArtworkInlineDetails
              className="inline-artwork-details"
              artwork={artwork}
              css={{ mt: "$1" }}
            />
          </Name>
          <Technique className="technique">
            <Text as="p" variant="label">
              <Trans id="artwork.technique">Technika</Trans>
            </Text>
            <Text as="p">
              {array.isNonEmpty(artwork.techniques) ? (
                <TechniquesView techniques={artwork.techniques} />
              ) : (
                <Text color="muted">—</Text>
              )}
            </Text>
          </Technique>
          <Dimensions className="dimensions">
            <Text as="p" variant="label">
              <Trans id="artwork.dimensions">Rozměry</Trans>
            </Text>
            <Text as="p">
              {Object.values(artwork.dimensions).some(
                (value) => value !== null
              ) ? (
                <DimensionsView dimensions={artwork.dimensions} />
              ) : (
                <Text color="muted">—</Text>
              )}
            </Text>
          </Dimensions>
          <Status>
            <Stack
              align="center"
              justify="center"
              css={{
                flex: 1,
                px: "$2",
                color: "$gray1",
                bgColor: "$gray12",
              }}
            >
              <Countdown endsAt={auction.dateEnd} figuresCount={figuresCount} />
            </Stack>
            <Stack
              align="center"
              justify="center"
              css={{
                flex: 1,
                px: "$2",
                py: "$2",
                bgColor: "$accent9",
                color: "$gray1",
              }}
            >
              <Text size="2xl" weight="black">
                <MoneyView
                  value={auction.currentPrice ?? auction.startingPrice}
                />
              </Text>
            </Stack>
          </Status>
        </ContainerGrid>
      </Link>
    </CurrencyProvider>
  )
}

export function AuctionCardSkeleton() {
  return (
    <Skeleton>
      <AspectRatio ratio={1} />
      <Box css={{ height: 66 }} />
    </Skeleton>
  )
}
