import { MessageDescriptor, i18n } from "@lingui/core"
import { defineMessage, plural } from "@lingui/macro"
import { intervalToDuration } from "date-fns"
import { array } from "fp-ts"
import { pipe } from "fp-ts/function"
import { useEffect, useState } from "react"

function durationKeyToMessage(
  n: number,
  key: keyof Duration
): MessageDescriptor {
  return {
    years: defineMessage({
      id: "counter.years",
      message: plural(n, {
        one: "rok",
        few: "roky",
        other: "roků",
        many: "roku",
      }),
    }),
    months: defineMessage({
      id: "counter.months",
      message: plural(n, {
        one: "měsíc",
        few: "měsíce",
        other: "měsíců",
        many: "měsíce",
      }),
    }),
    weeks: defineMessage({
      id: "counter.weeks",
      message: plural(n, {
        one: "týden",
        few: "týdny",
        other: "týdnů",
        many: "týdne",
      }),
    }),
    days: defineMessage({
      id: "counter.days",
      message: plural(n, {
        one: "den",
        few: "dny",
        other: "dnů",
        many: "dne",
      }),
    }),
    hours: defineMessage({
      id: "counter.hours",
      message: plural(n, {
        one: "hodina",
        few: "hodiny",
        other: "hodin",
        many: "hodiny",
      }),
    }),
    minutes: defineMessage({
      id: "counter.minutes",
      message: plural(n, {
        one: "minuta",
        few: "minuty",
        other: "minut",
        many: "minuty",
      }),
    }),
    seconds: defineMessage({
      id: "counter.seconds",
      message: plural(n, {
        one: "sekunda",
        few: "sekundy",
        other: "sekund",
        many: "sekundy",
      }),
    }),
  }[key]
}

interface Part {
  key: keyof Duration
  value: number
  label: string
}

interface UseCountdown {
  parts: Array<Part>
  overdue: boolean
  remainingMs: number
}

const stripLeftZeroes = <T extends { value: number }>(
  values: Array<T>
): Array<T> =>
  pipe(
    values,
    array.reduce([] as Array<T>, (array, item) =>
      array.length === 0 && item.value === 0 ? [] : [...array, item]
    )
  )

export function useTimer(interval = 1000) {
  const [now, setNow] = useState<number>(Date.now())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(Date.now())
    }, interval)

    return () => {
      clearInterval(intervalId)
    }
  })

  return now
}

export function useCountdown({
  endsAt,
  significantParts = 3,
}: {
  endsAt: Date
  significantParts?: number
}): UseCountdown {
  const now = useTimer()
  const duration = intervalToDuration({ start: now, end: endsAt })
  const remainingMs = endsAt.getTime() - now

  return {
    overdue: remainingMs <= 0,
    remainingMs,
    parts: pipe(
      Object.keys(duration),
      array.map((_key) => {
        const key = _key as keyof Duration
        const value = duration[key] ?? 0

        return {
          key,
          value: value,
          label: i18n._(durationKeyToMessage(value, key)),
        }
      }),
      stripLeftZeroes,
      array.takeLeft(significantParts)
    ),
  }
}
