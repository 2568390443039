import Image from "next/image"
import { ReactNode, useState } from "react"
import { Blurhash } from "react-blurhash"

import { styled } from "@/stitches"

import { AspectRatio } from "./aspect-ratio"
import { Box } from "./box"

export const ColorLayer = styled("div", {
  position: "absolute",
  inset: 0,
  transition: "background 120ms, opacity 120ms",
  ".group:hover &": {
    bg: "$accent9",
    opacity: 0.75,
    backdropFilter: "blur(10px)",
  },
  variants: {
    variant: {
      default: {},
      dimmed: {
        bg: "linear-gradient(10deg, $colors$blackA12 0%, $colors$blackA1 75%)",
      },
    },
  },
})

export const TextLayer = styled("div", {
  position: "absolute",
  inset: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-end",
  "@animation": {
    transition: "opacity 120ms, transform 120ms",
  },
  ".group:hover &": {
    opacity: 0,
    transform: "translateX(-5%)",
  },
})

export const IconLayer = styled("div", {
  position: "absolute",
  inset: 0,
  display: "grid",
  placeItems: "center",
  opacity: 0,
  transform: "translateX(10%)",
  color: "white",
  "@animation": {
    transition: "opacity 120ms, transform 120ms",
  },
  ".group:hover &": {
    opacity: 1,
    transform: "none",
  },
})

const StyledImage = styled(Image, {
  objectFit: "cover",
  objectPosition: "center",
  overflow: "hidden",
  transition: "opacity 240ms",

  variants: {
    loaded: {
      false: { opacity: 0 },
      true: { opacity: 1 },
    },
  },

  defaultVariants: {
    loaded: false,
  },
})

export type ImageProp = {
  url: string
  metadata: {
    blurHash: string
  }
}

export function AspectRatioImage({
  image,
  alt,
  ratio = 1,
  children,
  className,
}: {
  image?: ImageProp
  alt?: string
  ratio?: number
  children?: ReactNode
  className?: string
}) {
  const [loaded, setLoaded] = useState(false)
  const onLoad = () => setLoaded(true)

  return (
    <AspectRatio
      ratio={ratio}
      css={{ bgColor: "$gray6" }}
      className={className}
    >
      {image?.metadata.blurHash && (
        <Blurhash
          hash={image.metadata.blurHash}
          width="100%"
          height="100%"
          style={{ position: "absolute", inset: 0 }}
        />
      )}
      {image && (
        // This crazy nesting is necessary because `Image` must not be child
        // of element with `position: absolute`, otherwise browsers will complain.
        <Box css={{ position: "absolute", inset: 0 }}>
          <Box css={{ position: "relative", width: "100%", height: "100%" }}>
            <StyledImage
              src={image.url}
              alt={alt}
              layout="fill"
              sizes="25vw"
              onLoadingComplete={onLoad}
              loaded={loaded}
            />
          </Box>
        </Box>
      )}
      {children}
    </AspectRatio>
  )
}
