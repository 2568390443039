import { NonEmptyArray } from "fp-ts/NonEmptyArray"

import { useI18n } from "@/lib/i18n"

import { Technique } from "./model"

export function TechniquesView({
  techniques,
}: {
  techniques: NonEmptyArray<Technique>
}) {
  const { localize } = useI18n()

  return <>{techniques.map((tech) => localize(tech.name)).join(" / ")}</>
}
