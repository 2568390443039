import { array, option, record } from "fp-ts"
import { pipe } from "fp-ts/function"

import { useLength } from "../units"
import { Dimensions } from "./model"

export function DimensionsView({ dimensions }: { dimensions: Dimensions }) {
  const { formatLength } = useLength()

  const values = pipe(
    dimensions,
    record.toArray,
    array.filterMap(([, value]) => option.fromNullable(value)),
    array.map(formatLength)
  )

  return <>{values.join(" × ")}</>
}
