import { useSettings } from "@/domains/website-settings/lib"
import { useI18n } from "@/lib/i18n"

import { LengthUnit, cmToUnitRatio } from "./model"

const round = (n: number, power: number) => {
  const x = Math.pow(10, power)
  return Math.round(n * x) / x
}

const unitToI18nStyle = (unit: LengthUnit): string =>
  ({
    cm: "centimeter",
    inch: "inch",
  }[unit])

export function useLength() {
  const { lengthUnit } = useSettings()
  const { i18n } = useI18n()

  return {
    formatLength: (cm: number) =>
      i18n.number(round(cm * cmToUnitRatio(lengthUnit), 1), {
        style: "unit",
        unit: unitToI18nStyle(lengthUnit),
        unitDisplay: "narrow",
      }),
  }
}
