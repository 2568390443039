import * as SeparatorPrimitive from "@radix-ui/react-separator"

import { styled } from "@/stitches"

export const Separator = styled(SeparatorPrimitive.Root, {
  borderStyle: "solid",
  borderColor: "$divider",
  "&[data-orientation=horizontal]": {
    borderBottomWidth: 1,
  },
  "&[data-orientation=vertical]": {
    borderRightWidth: 1,
  },
})
