import { array } from "fp-ts"
import { Fragment, ReactNode } from "react"

import { DimensionsView } from "@/domains/dimensions/view"
import { TechniquesView } from "@/domains/techniques/view"
import { CSS } from "@/stitches"
import { Separator } from "@/ui/separator"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

import { ArtworkPreview } from "../model"

export function ArtworkInlineDetails(props: {
  artwork: ArtworkPreview
  className?: string
  css?: CSS
}) {
  const { techniques, dimensions } = props.artwork
  const entries: Array<ReactNode> = []

  if (array.isNonEmpty(techniques)) {
    entries.push(<TechniquesView key="techniques" techniques={techniques} />)
  }

  if (Object.values(dimensions).some((value) => value !== null)) {
    entries.push(<DimensionsView key="dimensions" dimensions={dimensions} />)
  }

  return (
    <Stack wrap columnGap={2} className={props.className} css={props.css}>
      {entries.map((entry, index) => (
        <Fragment key={index}>
          <Text variant="label">{entry}</Text>
          {index < entries.length - 1 && <Separator orientation="vertical" />}
        </Fragment>
      ))}
    </Stack>
  )
}
